body {
  background-color: #000 !important; /* Background color */
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(assets/images/background-header.jpg); 
  /* Darker overlay using linear-gradient combined with background image */
  background-repeat: no-repeat;
  background-size: 100% 100%; /* Fit to 100% width and height */
  background-position: center;

  margin: 0;
  padding: 0;
  height: 100vh; /* Ensure the body takes up the full viewport height */
  color: white; /* White text for readability */
}


.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.card {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white for glass effect */
  backdrop-filter: blur(10px); /* Blur for the glass effect */
  border: none; /* Remove border for cleaner glass look */
  color: white; /* White text for better contrast */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.card .card-body {
  color: white; /* Ensure text inside the card body is also white */
}

.card .card-title, 
.card .card-text {
  color: white; /* White text for titles and other text */
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list-group {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background for glass effect */
  backdrop-filter: blur(10px); /* Glass effect */
  border: none; /* Remove borders */
}

.list-group-item {
  background-color: rgba(0, 0, 0, 0.5); /* Darker background for list items */
  color: white; /* White text for readability */
  border: none; /* Remove borders */
}

/* Dark mode for the body */
body.dark-mode {
  background-color: #000;
  color: white;
}

/* Dark mode for card */
body.dark-mode .card {
  background: rgba(255, 255, 255, 0.2); /* Glass effect for card */
  backdrop-filter: blur(10px);
  color: white;
  border: none;
}

/* Dark mode for list-group and list-group-item */
body.dark-mode .list-group {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: none;
}

body.dark-mode .list-group-item {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
}

/* Dark mode for input fields, textareas, and selects */
body.dark-mode input, 
body.dark-mode textarea, 
body.dark-mode select {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

body.dark-mode input::placeholder, 
body.dark-mode textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

body.dark-mode select option {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}


.crypto-header-info {
  margin-top: 2%;
  margin-bottom: 2%;
}
.crypto-header-info a {
  text-decoration: none;
}
.crypto-header-info1 a {
  margin-top: 40px;
  font-size: 18px;
  background: gold; /* Golden background */
  border: 3px solid #ffd700; /* Border similar to Bitcoin color */
  padding: 10px 30px;
  color: #000; /* Black text color */
  border-radius: 50%; /* Circular button */
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.crypto-header-info1 a:hover {
  background: #ffd700;
  border: 3px solid #ffd700;
  color: #000;
}

.svg-icon {
  width: 100px;
  height: 100px;
  fill: rgb(255, 255, 255); /* Forces the color to silver */
}
