/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333; /* Dark background for the navbar */
  color: #fff; /* White text */
  padding: 1rem;
  border-radius: 0 !important;
  margin: 0 !important;

  z-index: 1000;
}

/* Logo on the left */
.navbar-left .logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff; /* White text for logo */
  text-decoration: none;
}

.navbar-left .logo span {
  color: yellowgreen; /* Yellowgreen for part of the logo */
}

/* Navbar links in the center */
.navbar-center .nav-links {
  list-style-type: none;
  display: flex; /* Display links in a row */
  margin: 0;
  padding: 0;
}

.navbar-center .nav-links li {
  margin-right: 1rem; /* Spacing between menu items */
}

/* Links default style */
.navbar-center .nav-links a {
  color: #fff; /* White text for links */
  text-decoration: none; /* Remove underline */
}

/* Hover effect for the links */
.navbar-center .nav-links a:hover {
  color: yellowgreen; /* Change link color on hover */
  transition: color 0.3s ease-in-out; /* Smooth color transition */
}

/* Right side icons (cart, user, etc.) */
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right .cart-icon,
.navbar-right .user-icon {
  color: #fff; /* White icons */
  text-decoration: none;
  margin-left: 1rem; /* Spacing between icons */
  position: relative;
}

/* Cart count badge */
.navbar-right .cart-count {
  background-color: #f44336; /* Red background for cart count */
  color: #fff;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}

/* Submenu style */
.nav-links ul {
  display: none; /* Hide submenu by default */
  position: absolute;
  top: 100%; /* Position below the main link */
  left: 0;
  background-color: white; /* White background for submenu */
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 0; /* Initially hidden */
  visibility: hidden;
  transform: translateY(-20px); /* Slide up when hidden */
  transition: all 0.2s ease-in-out; /* Fast, smooth animation */
}

.nav-links li {
  position: relative;
}

/* Show submenu on hover */
.nav-links li:hover > ul {
  display: block;
  opacity: 1; /* Make it visible */
  visibility: visible;
  transform: translateY(0); /* Slide down into place */
}

/* Submenu item styles */
.nav-links ul li {
  display: block; /* Block display for submenu items */
  padding: 10px; /* Padding for spacing */
  background-color: white; /* White background */
  color: black; /* Black text by default */
}

/* Hover effect for submenu items */
.nav-links ul li:hover {
  background-color: #adff2f; /* Green lemon background on hover */
  color: black; /* Keep text black */
  transition: background-color 0.3s ease-in-out; /* Smooth transition */
}

.nav-links ul li a {
  color: black !important;
}

.glass-navbar {
  background: rgba(255, 255, 255, 0.03); /* Semi-transparent white background */
  backdrop-filter: blur(10px); /* Blur effect for glass effect */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); /* Optional border */
}

/* Responsive styles */
.navbar-toggler {
  border: none;
  color: #fff;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.collapse.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  .navbar-center .nav-links {
    display: none; /* Hide navbar links on medium screens and smaller */
  }

  .collapse.navbar-collapse.show .nav-links {
    display: flex; /* Show navbar links when collapsed navbar is open */
    flex-direction: column; /* Display links in a column */
    align-items: center;
  }
}


/* Hamburger menu button */
.hamburger-menu {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

/* Center nav-links styling for smaller screens */
.navbar-center.show-menu .nav-links {
  display: flex;
  flex-direction: column;
  background-color: rgba(51, 51, 51, 0.9); /* Dark background for the dropdown */
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

.nav-links li {
  margin: 1rem 0; /* Adjust spacing for vertical links */
}

/* Responsive styles for the hamburger menu */
@media (max-width: 992px) {
  .hamburger-menu {
    display: block; /* Show hamburger button */
  }

  .navbar-center {
    display: none; /* Hide the center links by default */
    flex-direction: column;
    align-items: center;
    width: 100%; /* Take full width */
  }

  .navbar-center.show-menu {
    display: flex; /* Show the menu when toggled */
  }

  .nav-links {
    flex-direction: column;
  }
}
